<template>
    <!-- !! Note: !! -->
    <!-- Using this slot and replacing the whole button will result in the refocusing of the button not working -->
    <slot name="button" :open="open">
        <button ref="link"
                :disabled="disabled"
                :class="linkClasses"
                :title="buttonTitle"
                type="button"
                @click="open"><slot name="button-text">Delete</slot></button>
    </slot>

    <UserModal v-if="show" :top-tab="recordDesc" @opened="opened" @closed="closed">
        <div v-if="!deleting" class="text-center">
            <div class="my-12">
                <slot>
                    Are you sure you want to delete this {{ recordDesc }}?
                    <br>This cannot be undone.
                </slot>
            </div>
            <slot name="additional" />
            <div class="mt-8">
                <button class="button button-critical button-delete"
                        type="button"
                        @click="deleteRecord">Delete</button>
                <button ref="cancel"
                        class="form-action button-link block mx-auto mt-3"
                        type="button"
                        @click="close">Cancel</button>
            </div>
        </div>

        <LoadingSpinner v-else-if="!deleted" class="p-12 text-center">
            Deleting {{ recordDesc }}…
        </LoadingSpinner>
        <div v-else class="p-12 text-center">
            Deleted
        </div>
    </UserModal>
</template>

<script setup>
import { ref } from 'vue';

defineProps({
    recordDesc: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    linkClasses: {
        type: String,
        default: 'button-link form-action block mx-auto mt-16',
    },
    buttonTitle: {
        type: String,
        default: null,
    },
    deleted: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['delete']);

const show = ref(false);
const deleting = ref(false);

const cancel = ref(null);
const link = ref(null);

const open = () => {
    show.value = true;
};

const opened = () => {
    if (!deleting.value) {
        cancel.value.focus();
    }
};

const closed = () => {
    show.value = false;
    // reset everything else
    deleting.value = false;
    cancel.value = null;
    if (link.value) {
        link.value.focus();
    }
};

const deleteRecord = () => {
    deleting.value = true;
    emit('delete');
};

const close = () => {
    show.value = false;
    deleting.value = false;
    if (link.value) {
        link.value.focus();
    }
};
</script>
